import React, { useState } from "react";
import MyCard from "../../components/common/MyCard";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/material";
import { RxCross2 } from "react-icons/rx";
const AddBranch = ({ onClose }) => {
  const [branchName, setBranchName] = useState("");
  const [displayBranchName, setDisplayBranchName] = useState("");
  const [parentBranch, setParentBranch] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here to handle the API
    console.log("Branch Name:", branchName);

    // Clear the form
    setBranchName("");
    setDisplayBranchName("");
    setParentBranch("");
    setCity("");
    setDistrict("");
    setState("");
    setPinCode("");
    onClose();
  };

  return (
    <>
      <MyCard
        title={
          <div className="flex justify-between items-center">
            <span>Add Branch</span>
            <RxCross2 onClick={onClose} className="cursor-pointer" />
          </div>
        }
        width={1000}
      >
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-4 "
        >
          <div>
            <TextField
              label="Branch Name"
              variant="outlined"
              fullWidth
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="Display Branch Name"
              variant="outlined"
              fullWidth
              value={displayBranchName}
              onChange={(e) => setDisplayBranchName(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="Parent Branch"
              variant="outlined"
              fullWidth
              value={parentBranch}
              onChange={(e) => setParentBranch(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label=" District"
              variant="outlined"
              fullWidth
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              label="Pincode"
              variant="outlined"
              fullWidth
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              required
            />
          </div>

          <div className="col-span-2 sm:col-span-2 flex justify-end">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </MyCard>
    </>
  );
};

export default AddBranch;
