import React, { useState, useEffect } from "react";
import { BsArrowBarRight } from "react-icons/bs";

const TreeViewComponent = ({ node, parentbranchkey, childbranchkey }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className=" mb-1 py-1 tracking-wide text-md ">
      <div className="flex items-center cursor-pointer " onClick={toggle}>
        {node[childbranchkey] && (
          <span
            className={`transition-transform ${isOpen ? "rotate-90" : " "}`}
          >
            <BsArrowBarRight />
          </span>
        )}

        <span
          className={`pr-5 py-1 ${node[childbranchkey] ? "font-bold" : ""}`}
        >
          {node[parentbranchkey]}
        </span>
      </div>
      {isOpen && node[childbranchkey] && (
        <div className="pl-10">
          {node[childbranchkey].map((item) => (
            <TreeViewComponent
              key={item.key}
              node={item}
              parentbranchkey={parentbranchkey}
              childbranchkey={childbranchkey}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeViewComponent;
