import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, useLoadScript, Circle } from "@react-google-maps/api";

const libraries = ["marker"];
const mapId = "YOUR_MAP_ID"; // Replace with your actual Map ID

const GeoFencing = ({ formData, onStepDataChange }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [radius, setRadius] = useState(formData.radius || 20);
  const [location, setLocation] = useState({
    lat: formData.lat || 0,
    lng: formData.lng || 0,
  });

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation && (location.lat === 0 || location.lng === 0)) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(userLocation);
          onStepDataChange({ radius, ...userLocation });
        },
        (error) => {
          console.error("Error fetching location", error);
          alert(
            "Unable to fetch current location. Please allow location access."
          );
        }
      );
    }
  }, [location, radius, onStepDataChange]);

  const handleRadiusChange = (e) => {
    const newRadius = Number(e.target.value);
    setRadius(newRadius);
    onStepDataChange({
      radius: newRadius,
      lat: location.lat,
      lng: location.lng,
    });
  };

  const handleMapClick = (e) => {
    const clickedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setLocation(clickedLocation);
    onStepDataChange({ radius, ...clickedLocation });
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }

      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        position: location,
        map: mapRef.current,
        title: "Selected Location",
      });
    }
  }, [location, isLoaded]);

  if (!isLoaded) return <p>Loading...</p>;

  return (
    <div className="space-y-4">
      <label className="block">
        <span className="text-gray-700">Enter Radius (meters):</span>
        <input
          value={radius}
          onChange={handleRadiusChange}
          className="mt-1 block w-full px-3 py-2 border rounded-md"
        />
      </label>
      <div className="w-full h-96">
        <GoogleMap
          onLoad={handleMapLoad}
          center={location}
          zoom={12}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          onClick={handleMapClick}
          mapId={mapId} // Map ID required for AdvancedMarkerElement
        >
          <Circle
            center={location}
            radius={radius}
            options={{ fillColor: "#FF0000", fillOpacity: 0.3 }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default GeoFencing;
