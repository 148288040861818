import React, { useState } from "react";
import { TextField, Button, IconButton, Select, MenuItem } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import MyCard from "../../components/common/MyCard";

const DeptDesn = ({ formData, onStepDataChange }) => {
  const [departments, setDepartments] = useState(formData?.departments || []);
  const [departmentName, setDepartmentName] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isEditingDept, setIsEditingDept] = useState(false);
  const [editingDeptOriginal, setEditingDeptOriginal] = useState("");
  const [isEditingDesg, setIsEditingDesg] = useState(false);
  const [editingDesgOriginal, setEditingDesgOriginal] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Add department if it doesn't already exist (case-insensitive)
  const handleAddDepartment = () => {
    if (departmentName) {
      const normalizedDepartmentName = departmentName.toLowerCase();

      if (
        !departments.some(
          (dept) => dept.name.toLowerCase() === normalizedDepartmentName
        )
      ) {
        const newDepartment = { name: departmentName, designations: [] };
        setDepartments([...departments, newDepartment]);
        setDepartmentName("");
        onStepDataChange({ departments });
      } else {
        alert("Department already exists or name is empty.");
      }
    } else {
      alert("Please enter a department name.");
    }
  };

  // Add designation to the selected department if it doesn't already exist (case-insensitive)
  const handleAddDesignation = () => {
    if (selectedDepartment && designationName) {
      const normalizedDesignationName = designationName.toLowerCase();

      setDepartments((prevDepts) =>
        prevDepts.map((dept) => {
          if (dept.name === selectedDepartment) {
            // Check if the designation already exists (case-insensitive)
            if (
              !dept.designations.some(
                (desg) => desg.toLowerCase() === normalizedDesignationName
              )
            ) {
              return {
                ...dept,
                designations: [...dept.designations, designationName],
              };
            } else {
              alert("Designation already exists in this department.");
              return dept;
            }
          }
          return dept;
        })
      );
      setDesignationName("");
      onStepDataChange({ departments });
    } else {
      alert("Please select a department and enter a designation name.");
    }
  };
  // Edit department name
  const handleEditDepartment = (deptName) => {
    setEditingDeptOriginal(deptName);
    setDepartmentName(deptName);
    setIsEditingDept(true);
  };

  // Update department name with duplication check (case-insensitive)
  const handleUpdateDepartment = () => {
    if (isEditingDept && departmentName) {
      const normalizedDepartmentName = departmentName.toLowerCase();
      const normalizedEditingDeptOriginal = editingDeptOriginal.toLowerCase();

      if (
        departments.some(
          (dept) =>
            dept.name.toLowerCase() === normalizedDepartmentName &&
            dept.name.toLowerCase() !== normalizedEditingDeptOriginal
        )
      ) {
        alert("A department with this name already exists.");
      } else {
        setDepartments((prevDepts) =>
          prevDepts.map((dept) =>
            dept.name.toLowerCase() === normalizedEditingDeptOriginal
              ? { ...dept, name: departmentName }
              : dept
          )
        );
        setIsEditingDept(false);
        setDepartmentName("");
        onStepDataChange({ departments });
      }
    }
  };

  // Edit designation name
  const handleEditDesignation = (deptName, designation) => {
    setSelectedDepartment(deptName);
    setDesignationName(designation);
    setEditingDesgOriginal(designation);
    setIsEditingDesg(true);
  };

  // Update designation name with duplication check
  const handleUpdateDesignation = () => {
    if (isEditingDesg && designationName) {
      setDepartments((prevDepts) =>
        prevDepts.map((dept) => {
          if (dept.name === selectedDepartment) {
            // Convert both to lowercase to make comparison case-insensitive
            const normalizedDesignationName = designationName.toLowerCase();
            const normalizedOriginalDesignation =
              editingDesgOriginal.toLowerCase();

            if (
              dept.designations.some(
                (desg) => desg.toLowerCase() === normalizedDesignationName
              ) &&
              normalizedDesignationName !== normalizedOriginalDesignation
            ) {
              alert(
                "A designation with this name already exists in this department."
              );
              return dept;
            }

            return {
              ...dept,
              designations: dept.designations.map((desg) =>
                desg.toLowerCase() === normalizedOriginalDesignation
                  ? designationName
                  : desg
              ),
            };
          }
          return dept;
        })
      );
      setIsEditingDesg(false);
      setDesignationName("");
      onStepDataChange({ departments });
    }
  };

  // Filter departments based on search term
  const filteredDepartments = departments.filter((dept) =>
    dept.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      {/* Department and Designation Input Row */}
      <div className="flex space-x-4 ">
        {/* Department Input */}
        <MyCard width={"40%"} title={"Add Department"}>
          <div className="flex flex-row gap-x-2">
            <TextField
              label="Department Name"
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ width: "66%" }} // Sets width to 2/3 of the container
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
              onClick={
                isEditingDept ? handleUpdateDepartment : handleAddDepartment
              }
            >
              {isEditingDept ? "Update Department" : "Add Department"}
            </Button>
          </div>
          {/* Department Table */}
          <div className="w-full space-y-4">
            <h3 className="text-xl font-semibold my-5  ">
              <span className="border-b border-gray-300 pb-2 inline-block">
                Departments
              </span>
            </h3>
            <table className="w-full border border-gray-300 text-center">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="border-r border-gray-300 p-2">Department</th>
                  <th className="p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {departments.map((dept, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="border-r border-gray-300 p-2">
                      {dept.name}
                    </td>
                    <td className="p-2">
                      <IconButton
                        onClick={() => handleEditDepartment(dept.name)}
                        color="primary"
                      >
                        <MdModeEdit />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </MyCard>
        {/* Designation Input */}
        <MyCard width={"60%"} title={"Add Designation"}>
          <div className="flex flex-row gap-x-2">
            <Select
              fullWidth
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              displayEmpty
              sx={{ width: "37%", height: "40px" }}
              size="small"
            >
              <MenuItem value="" disabled>
                Select Department
              </MenuItem>
              {departments.map((dept, index) => (
                <MenuItem key={index} value={dept.name}>
                  {dept.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              label="Designation Name"
              value={designationName}
              size="small"
              onChange={(e) => setDesignationName(e.target.value)}
              variant="outlined"
              sx={{ width: "37%" }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ width: "26%" }}
              sx={{ whiteSpace: "nowrap" }}
              onClick={
                isEditingDesg ? handleUpdateDesignation : handleAddDesignation
              }
            >
              {isEditingDesg ? "Update Designation" : "Add Designation"}
            </Button>
          </div>
          {/* Designation Table with Search Dropdown */}
          <div className="w-full flex flex-row space-x-4 space-y-4">
            <h3 className="text-xl font-semibold my-5  ">
              <span className="border-b border-gray-300 pb-2 inline-block">
                Designations by Department
              </span>
            </h3>

            <Select
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              displayEmpty
              className="mb-4"
              sx={{
                height: "40px",
              }}
            >
              <MenuItem value="">All Departments</MenuItem>
              {departments.map((dept, index) => (
                <MenuItem key={index} value={dept.name}>
                  {dept.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <table className="w-full border border-gray-300 text-center">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="border-r border-gray-300 p-2">Department</th>
                <th className="border-r border-gray-300 p-2">Designation</th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredDepartments.map((dept) =>
                dept.designations.map((desg, idx) => (
                  <tr key={idx} className="border-b border-gray-300">
                    <td className="border-r border-gray-200 p-2">
                      {dept.name}
                    </td>
                    <td className="border-r border-gray-200 p-2">{desg}</td>
                    <td className="p-2">
                      <IconButton
                        onClick={() => handleEditDesignation(dept.name, desg)}
                        color="primary"
                      >
                        <MdModeEdit />
                      </IconButton>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </MyCard>
      </div>
    </div>
  );
};

export default DeptDesn;
