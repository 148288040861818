import React, { useState } from "react";
import MyCard from "../../components/common/MyCard";
import { DataTable } from "../../components/DataTable";
import { Button, IconButton, Box } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const RegisteredUsers = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [data, setData] = useState([
    { id: 1, name: "John Doe", age: 28, email: "johndoe@example.com" },
    { id: 2, name: "Jane Smith", age: 34, email: "janesmith@example.com" },
    { id: 3, name: "Sara Lee", age: 22, email: "saralee@example.com" },
    { id: 4, name: "James Brown", age: 45, email: "jamesbrown@example.com" },
    { id: 5, name: "Emily Davis", age: 30, email: "emilydavis@example.com" },
    {
      id: 6,
      name: "Michael Green",
      age: 31,
      email: "michaelgreen@example.com",
    },
    {
      id: 7,
      name: "Chris Johnson",
      age: 27,
      email: "chrisjohnson@example.com",
    },
    { id: 8, name: "John Doe", age: 28, email: "johndoe@example.com" },
    { id: 9, name: "Jane Smith", age: 34, email: "janesmith@example.com" },
    { id: 10, name: "Sara Lee", age: 22, email: "saralee@example.com" },
    { id: 11, name: "James Brown", age: 45, email: "jamesbrown@example.com" },
    { id: 12, name: "Emily Davis", age: 30, email: "emilydavis@example.com" },
  ]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "age", headerName: "Age", type: "number", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
  ];

  // Edit row function
  const handleEdit = (id) => {
    console.log("Edit row with ID:", id);
    // Logic for editing the row (e.g., opening an edit form)
  };

  // Delete row function
  const handleDelete = (id) => {
    setData((prevData) => prevData.filter((row) => row.id !== id));
    console.log("Deleted row with ID:", id);
  };

  // Custom Actions Component
  const renderActions = (params) => {
    const { id } = params.row;
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          color="primary"
          onClick={() => handleEdit(id)} // Custom edit handler
          sx={{ marginRight: 2 }}
        >
          <Edit />
        </IconButton>
        <IconButton
          color="secondary"
          onClick={() => handleDelete(id)} // Custom delete handler
        >
          <Delete />
        </IconButton>
      </Box>
    );
  };

  // const handleView = (company) => {
  //   setSelectedCompany(company);
  //   setShowModal(true);
  // };

  // const handleApprove = (companyId) => {
  //   setData((prevData) =>
  //     prevData.map((company) =>
  //       company.sNO === companyId ? { ...company, status: "Approved" } : company
  //     )
  //   );
  // };

  // const handleReject = (companyId) => {
  //   setData((prevData) =>
  //     prevData.map((company) =>
  //       company.sNO === companyId ? { ...company, status: "Rejected" } : company
  //     )
  //   );
  // };

  return (
    <>
      <MyCard title="Registered Users" width={"96%"}>
        <DataTable
          data={data}
          columns={columns}
          renderActions={renderActions}
          pageSize={10}
        />
      </MyCard>

      {showModal && selectedCompany && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <MyCard
            title={
              <div className="flex justify-between items-center">
                <span>Company Details</span>
              </div>
            }
            width={800}
          >
            <table className="min-w-full bg-white border">
              <tbody>
                <tr>
                  <td className="px-4 py-2 border">S. No</td>
                  <td className="px-4 py-2 border">{selectedCompany.sNO}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">Company Name</td>
                  <td className="px-4 py-2 border">
                    {selectedCompany.companyName}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">Est. Year</td>
                  <td className="px-4 py-2 border">
                    {selectedCompany.estYear}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">Ownership</td>
                  <td className="px-4 py-2 border">
                    {selectedCompany.ownership}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">No. of Employees</td>
                  <td className="px-4 py-2 border">
                    {selectedCompany.noOfEmployees}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">PAN</td>
                  <td className="px-4 py-2 border">{selectedCompany.pan}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border">GST</td>
                  <td className="px-4 py-2 border">{selectedCompany.gst}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end mt-5">
              <Button variant="outlined" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </div>
          </MyCard>
        </div>
      )}
    </>
  );
};

export default RegisteredUsers;
