import React, { useEffect } from "react";
import { TextField, MenuItem, Link, Box, Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";

const StepTwo = ({ validate, initVals, contactNumber }) => {
  const {
    data: ownershiptype,
    loading: ownershipLoading,
    error: ownershipError,
  } = useFetch("/meta/company-ownership-type");
  const {
    data: state,
    loading: stateLoading,
    error: stateError,
  } = useFetch("/meta/state");
  const {
    data: businessNature,
    loading: natureLoading,
    error: natureError,
  } = useFetch("/meta/company-nature-of-business");

  useEffect(() => {
    // console.log("ownershipType: ", data);
    // console.log("ownershipLoading: ", loading);
    // console.log("ownershipError: ", error);
  });

  const formik = useFormik({
    initialValues: {
      registered_name: initVals ? initVals["registered_name"] : "",
      establishment_year: initVals ? initVals["establishment_year"] : "",
      ownership_type_id: initVals ? initVals["ownership_type_id"] : "",
      annual_turnover: initVals ? initVals["annual_turnover"] : "",
      company_contact_number: initVals
        ? initVals["company_contact_number"]
        : contactNumber
        ? contactNumber
        : "",
      nature_of_business_id: initVals ? initVals["nature_of_business_id"] : "",
      number_of_branches: initVals ? initVals["number_of_branches"] : "",
      number_of_employees: initVals ? initVals["number_of_employees"] : "",
      pan: initVals ? initVals["pan"] : "",
      gst: initVals ? initVals["gst"] : "",
      state_id: initVals ? initVals["state_id"] : "",
      city: initVals ? initVals["city"] : "",
      pincode: initVals ? initVals["pincode"] : "",
    },
    validationSchema: Yup.object({
      registered_name: Yup.string().required(
        "Registered Company name is required."
      ),
      company_contact_number: Yup.number().required(
        "Contact number is required."
      ),
      ownership_type_id: Yup.number().required("Ownership type is required."),
      nature_of_business_id: Yup.number().required(
        "Nature of business is required."
      ),
      number_of_branches: Yup.number().required(
        "Number of branches is required."
      ),
      gst: Yup.string().required("Company GST is required."),
      state_id: Yup.number().required("State is required."),
      city: Yup.string().required("City is required."),
      pincode: Yup.number().required("Pincode is required."),
    }),
    onSubmit: (values) => {
      // Call the validate function to indicate successful validation
      validate(true, "", values); // Valid form submission
      console.log("Form submitted:", values);
    },
  });

  const handleBlur = (event) => {
    formik.handleBlur(event);
    const { name } = event.target;
    const isValid = !formik.errors[name] && formik.touched[name];

    // Call validate function with the current validation state
    validate(isValid, isValid ? "" : formik.errors[name], formik.values);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            row
          >
            <Grid item size={6}>
              <TextField
                label="Registered Company Name"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="registered_name"
                value={formik.values.registered_name}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.registered_name &&
                  Boolean(formik.errors.registered_name)
                }
                helperText={
                  formik.touched.registered_name &&
                  formik.errors.registered_name
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Establishment Year"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="establishment_year"
                value={formik.values.establishment_year}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.establishment_year &&
                  Boolean(formik.errors.establishment_year)
                }
                helperText={
                  formik.touched.establishment_year &&
                  formik.errors.establishment_year
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={ownershiptype || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "ownership_type_id",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={formik.handleBlur}
                name="ownership_type_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ownership Type"
                    variant="outlined"
                    margin="normal"
                    error={
                      formik.touched.ownership_type_id &&
                      Boolean(formik.errors.ownership_type_id)
                    }
                    helperText={
                      formik.touched.ownership_type_id &&
                      formik.errors.ownership_type_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Anual Turnover"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="annual_turnover"
                value={formik.values.annual_turnover}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.annual_turnover &&
                  Boolean(formik.errors.annual_turnover)
                }
                helperText={
                  formik.touched.annual_turnover &&
                  formik.errors.annual_turnover
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                name="company_contact_number"
                value={formik.values.company_contact_number}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.company_contact_number &&
                  Boolean(formik.errors.company_contact_number)
                }
                helperText={
                  formik.touched.company_contact_number &&
                  formik.errors.company_contact_number
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={businessNature || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "nature_of_business_id",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={formik.handleBlur}
                name="nature_of_business_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nature of Business"
                    variant="outlined"
                    margin="normal"
                    error={
                      formik.touched.nature_of_business_id &&
                      Boolean(formik.errors.nature_of_business_id)
                    }
                    helperText={
                      formik.touched.nature_of_business_id &&
                      formik.errors.nature_of_business_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Number of Branches"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                name="number_of_branches"
                value={formik.values.number_of_branches}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.number_of_branches &&
                  Boolean(formik.errors.number_of_branches)
                }
                helperText={
                  formik.touched.number_of_branches &&
                  formik.errors.number_of_branches
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Number of Employees"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                name="number_of_employees"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.number_of_employees &&
                  Boolean(formik.errors.number_of_employees)
                }
                helperText={
                  formik.touched.number_of_employees &&
                  formik.errors.number_of_employees
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Company PAN"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="pan"
                value={formik.values.pan}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={formik.touched.pan && Boolean(formik.errors.pan)}
                helperText={formik.touched.pan && formik.errors.pan}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Company GST"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="gst"
                value={formik.values.gst}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={formik.touched.gst && Boolean(formik.errors.gst)}
                helperText={formik.touched.gst && formik.errors.gst}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={state || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                onChange={(event, value) => {
                  formik.setFieldValue("state_id", value ? value.id : ""); // Set the selected country code
                }}
                onBlur={formik.handleBlur}
                name="state_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    margin="normal"
                    error={
                      formik.touched.state_id && Boolean(formik.errors.state_id)
                    }
                    helperText={
                      formik.touched.state_id && formik.errors.state_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="City"
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Pincode"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                name="pincode"
                value={formik.values.pincode}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                helperText={formik.touched.pincode && formik.errors.pincode}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default StepTwo;
