import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const DateTimePicker = () => {
  const [dateTime, setDateTime] = useState(moment());
  const [pickerType, setPickerType] = useState("date"); // 'date' or 'dateTime'

  const handlePickerTypeChange = (event) => {
    setPickerType(event.target.value);
  };

  const handleDateChange = (newValue) => {
    setDateTime(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Picker Type</InputLabel>
        <Select value={pickerType} onChange={handlePickerTypeChange}>
          <MenuItem value="date">Date Picker</MenuItem>
          <MenuItem value="dateTime">Date & Time Picker</MenuItem>
        </Select>
      </FormControl>

      {pickerType === "date" ? (
        <DatePicker
          label="Select Date"
          value={dateTime}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      ) : (
        <TimePicker
          label="Select Time"
          value={dateTime}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(dateTime.format()); // Log the selected date/time
        }}
        style={{ marginTop: "16px" }}
      >
        Submit
      </Button>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
