import React from "react";
import * as Yup from "yup";
import DynamicForm from "../../components/common/DynamicForm";
import moment from "moment";
import DateTimePicker from "../../components/common/DateTimePicker";

const Stats = () => {
  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      validation: Yup.string().required("Required"),
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      validation: Yup.string()
        .email("Invalid email format")
        .required("Required"),
    },
    {
      name: "role",
      label: "Role",
      type: "select",
      options: [
        { value: "admin", label: "Admin" },
        { value: "user", label: "User " },
        { value: "guest", label: "Guest" },
      ],
      validation: Yup.string().required("Required"),
    },
    {
      name: "gender",
      label: "Gender",
      type: "radio",
      options: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
      ],
      validation: Yup.string().required("Required"),
    },
    {
      name: "subscribe",
      label: "Subscribe to Newsletter",
      type: "checkbox",
      validation: Yup.boolean(),
    },
    {
      name: "birthdate",
      label: "Birth Date",
      type: "date",
      validation: Yup.date().required("Required").nullable(),
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Stats</h1>
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        <h1>Dynamic Form Example</h1>
        <DynamicForm fields={fields} />
        {/* <DateTimePicker /> */}
      </div>
    </div>
  );
};

export default Stats;
