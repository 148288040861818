import React, { useState } from "react";
import logo from "../../assets/klockmatehome.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div>
            <img src={logo} width="120" alt="Logo" />
          </div>

          {/* Hamburger Menu Button for Mobile View */}
          <div className="flex md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          {/* Menu Items for Desktop View */}
          <div className="hidden md:block">
            <div className="flex justify-center space-x-10 tracking-wider text-md mx-10">
              <a href="#home" className="text-gray-700 hover:text-blue-600">
                Home
              </a>
              <a href="#aboutus" className="text-gray-700 hover:text-blue-600">
                About us
              </a>
              <a
                href="#ourfeatures"
                className="text-gray-700 hover:text-blue-600"
              >
                Our Features
              </a>
              <a
                href="#flowchart"
                className="text-gray-700 hover:text-blue-600"
              >
                Flow Chart
              </a>
              <a
                href="#contactus"
                className="text-gray-700 hover:text-blue-600"
              >
                Contact Us
              </a>
              <a
                onClick={() => navigate("/preregister")}
                className="text-gray-700 hover:text-blue-600"
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white shadow-md">
          <div className="px-4 py-2">
            <a
              href="#home"
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              Home
            </a>
            <a
              href="#aboutus"
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              About us
            </a>
            <a
              href="#ourfeatures"
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              Our Features
            </a>
            <a
              href="#flowchart"
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              Flow Chart
            </a>
            <a
              href="#contact"
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              Contact Us
            </a>
            <a
              onClick={() => navigate("/preregister")}
              className="block text-gray-700 hover:text-blue-600 py-2"
            >
              Log In
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
