import React, { useState } from "react";
import { TextField, Link, Box, Autocomplete, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";
import OtpInput from "../../components/OtpInput";
import axiosInstance from "../../axios/axiosInstance";

const StepOne = ({
  validate,
  initVals,
  setIsEmailVerified,
  setIsMobileVerified,
}) => {
  const { data, loading, error } = useFetch("/meta/country");
  const [showOtpInput, setShowOtpInput] = useState({
    company_contact_number: false,
    company_email: false,
  });
  const [showOtpMsg, setShowOtpMsg] = useState({
    mobile: "",
    email: "",
    mobileOTPTextColor: "primary",
    emailOTPTextColor: "primary",
  });

  const formik = useFormik({
    initialValues: {
      country_id: initVals ? initVals["country_id"] : "",
      company_contact_number: initVals
        ? initVals["company_contact_number"]
        : "",
      company_email: initVals ? initVals["company_email"] : "",
    },
    validationSchema: Yup.object({
      country_id: Yup.number().required("Country is required."),
      company_contact_number: Yup.string().required(
        "Contact number is required."
      ),
      company_email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required."),
    }),
    onSubmit: (values) => {
      // Call the validate function to indicate successful validation
      validate(true, "", values); // Valid form submission
      console.log("Form submitted:", values);
    },
  });

  const handleBlur = (event) => {
    formik.handleBlur(event);
    const { name } = event.target;
    const isValid = !formik.errors[name] && formik.touched[name];

    // Call validate function with the current validation state
    validate(isValid, isValid ? "" : formik.errors[name], formik.values);
  };

  const sendOTP = async () => {
    try {
      const response = await axiosInstance.post("/company/send-mobile-otp", {
        contact_number: formik.values.company_contact_number,
      });
      //   setData(response.data);
      console.log("OTP sent successfully:", response.data);
      setOtpMsg(
        "mobile",
        "OTP has been sent to your Mobile Number..!!",
        "primary"
      );
    } catch (err) {
      console.log(err.message);
      setOtpMsg(
        "mobile",
        "Something went wrong!!, Please try again later.",
        "error"
      );
    }
  };

  const sendEmail = async () => {
    try {
      const response = await axiosInstance.post("/company/send-email-otp", {
        email: formik.values.company_email,
      });
      //   setData(response.data);
      console.log("OTP sent successfully:", response.data);
      setOtpMsg("email", "OTP has been sent to your Email..!!", "primary");
    } catch (err) {
      console.log(err.message);
      setOtpMsg(
        "email",
        "Something went wrong, Please try again later.!!",
        "error"
      );
    }
  };

  const setOtpMsg = (type, msg, msgTextColor) => {
    if (type == "mobile") {
      setShowOtpMsg((prevState) => ({
        ...prevState,
        mobile: msg,
        mobileOTPTextColor: msgTextColor,
      }));
    } else if (type == "email") {
      setShowOtpMsg((prevState) => ({
        ...prevState,
        email: msg,
        emailOTPTextColor: msgTextColor,
      }));
    }
  };

  const handleVerifyClick = (type) => {
    if (
      type == "company_contact_number" &&
      formik.values.company_contact_number
    ) {
      setShowOtpInput((prev) => ({ ...prev, [type]: true }));
      sendOTP();
    } else if (type == "company_email" && formik.values.company_email) {
      setShowOtpInput((prev) => ({ ...prev, [type]: true }));
      sendEmail();
    }
  };

  const handleOtpSubmit = async (otp, type) => {
    if (type == "mobile") {
      try {
        const response = await axiosInstance.post(
          "/company/verify-mobile-otp",
          {
            contact_number: formik.values.company_contact_number,
            otp: otp,
          }
        );
        if (response.data.status == 200) {
          setIsMobileVerified(true);
          setOtpMsg(
            "mobile",
            "Your contact number verified successfully",
            "green"
          );
        } else {
          setOtpMsg("mobile", response.data.error, "red");
        }
      } catch (err) {
        console.log(err);
      }
    } else if (type == "email") {
      try {
        const response = await axiosInstance.post("/company/verify-email-otp", {
          email: formik.values.company_email,
          otp: otp,
        });
        if (response.data.status == 200) {
          setIsEmailVerified(true);
          setOtpMsg(
            "email",
            "Your email has been verified successfully",
            "green"
          );
        } else {
          setOtpMsg("email", response.data.error, "red");
        }
      } catch (err) {
        console.log(err);
      }
    }
    // Add your OTP verification logic here
  };

  const handleResend = (type) => {
    if (type == "mobile") {
      console.log("Resending Mobile OTP...");
      sendOTP();
    } else if (type == "email") {
      console.log("Resending Email OTP...");
      sendEmail();
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item size={8}>
              <Autocomplete
                options={data || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                onChange={(event, value) => {
                  formik.setFieldValue("country_id", value ? value.id : ""); // Set the selected country code
                }}
                onBlur={formik.handleBlur}
                name="country_id"
                value={
                  formik.values.country_id
                    ? data.find((item) => item.id === formik.values.country_id)
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Country"
                    variant="outlined"
                    margin="normal"
                    error={
                      formik.touched.country_id &&
                      Boolean(formik.errors.country_id)
                    }
                    helperText={
                      formik.touched.country_id && formik.errors.country_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={8}>
              <TextField
                name="company_contact_number"
                label="Contact Number"
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                value={formik.values.company_contact_number}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.company_contact_number &&
                  Boolean(formik.errors.company_contact_number)
                }
                helperText={
                  formik.touched.company_contact_number &&
                  formik.errors.company_contact_number
                }
                required
                InputProps={{
                  endAdornment: (
                    <Link
                      href="#"
                      underline="hover"
                      onClick={(e) => {
                        e.preventDefault();
                        handleVerifyClick("company_contact_number"); // Show OTP input for mobile
                      }}
                    >
                      Verify
                    </Link>
                  ),
                }}
              />
              {showOtpInput.company_contact_number && (
                <OtpInput
                  onSubmit={handleOtpSubmit}
                  onResend={handleResend}
                  type="mobile"
                />
              )}
              {showOtpMsg.mobile && (
                <Typography color={showOtpMsg.mobileOTPTextColor}>
                  {showOtpMsg.mobile}
                </Typography>
              )}
            </Grid>
            <Grid item size={8}>
              <TextField
                name="company_email"
                label="Email Address"
                variant="outlined"
                margin="normal"
                type="email"
                value={formik.values.company_email}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use custom handleBlur
                error={
                  formik.touched.company_email &&
                  Boolean(formik.errors.company_email)
                }
                helperText={
                  formik.touched.company_email && formik.errors.company_email
                }
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Link
                      href="#"
                      underline="hover"
                      onClick={(e) => {
                        e.preventDefault();
                        handleVerifyClick("company_email"); // Show OTP input for company_email
                      }}
                    >
                      Verify
                    </Link>
                  ),
                }}
              />
              {showOtpInput.company_email && (
                <OtpInput
                  onSubmit={handleOtpSubmit}
                  onResend={handleResend}
                  type="email"
                />
              )}
              {showOtpMsg.email && (
                <Typography color={showOtpMsg.emailOTPTextColor}>
                  {showOtpMsg.email}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default StepOne;
