import React from "react";
import MyCard from "../../../components/common/MyCard";
import { IconButton } from "@mui/material";
import { MdModeEdit } from "react-icons/md";

const GroupView = ({ group, index, handleOpenPopup }) => {
  return (
    <MyCard
      key={index}
      width="full"
      title={
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-bold">{group.groupName}</h3>
          <IconButton onClick={() => handleOpenPopup(group, index)}>
            <MdModeEdit />
          </IconButton>
        </div>
      }
    >
      {/* Conditionally render only non-empty fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
        <div className="flex-1">
          {group.branch && (
            <p>
              <strong>Branch:</strong> {group.branch}
            </p>
          )}
          {group.department && (
            <p>
              <strong>Department:</strong> {group.department}
            </p>
          )}
          {group.designations && (
            <p>
              <strong>Designations:</strong> {group.designations}
            </p>
          )}
          {group.includeEmployees && (
            <p>
              <strong>Include Employees:</strong> {group.includeEmployees}
            </p>
          )}
          {group.excludeEmployees && (
            <p>
              <strong>Exclude Employees:</strong> {group.excludeEmployees}
            </p>
          )}
        </div>
        <div className="flex-1">
          <strong>Check-In Criteria:</strong>
          <ul>
            {group.checkInCriteria.officeCheckIn && (
              <li>Enable Office Check-In</li>
            )}
            {group.checkInCriteria.staticTime && (
              <li className="pl-5 ">
                Static Time: {group.checkInCriteria.staticTime}
              </li>
            )}
            {group.checkInCriteria.blockEmployee && (
              <li className="pl-5">Block Employee If Outside Premises</li>
            )}
            {group.checkInCriteria.fieldCheckIn && (
              <li>Enable Field Check-In</li>
            )}
            {group.checkInCriteria.trackingFrequency && (
              <li className="pl-5">
                Track Frequency: {group.checkInCriteria.trackingFrequency}
              </li>
            )}
            {group.checkInCriteria.trackDeviceStatic && (
              <li className="pl-5">
                Track Device Static: {group.checkInCriteria.trackDeviceStatic}
              </li>
            )}
            {group.checkInCriteria.faceValidation && (
              <li>Enable Face Validation</li>
            )}
          </ul>
        </div>
      </div>
    </MyCard>
  );
};
export default GroupView;
