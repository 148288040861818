import React from "react";

export default function MyButton({
  className,
  children,
  variant = "default",
  size = "md",
  onClick,
  disabled,
  ...rest
}) {
  const handleClick = (event) => {
    if (!disabled) {
      onClick?.(event);
    }
  };

  const variants = {
    default:
      " text-white shadow-xl rounded-md track-wider bg-gradient-to-r to-theme-blue from-sky-400 font-semibold ",
  };

  const sizes = {
    sx: "text-xs px-2 py-1 ",
    sm: "text-sm px-2.5 py-1.5 ",
    md: "text-base px-3 py-2 ",
    lg: "text-lg px-3.5 py-2.5 ",
    xl: "text-xl px-4 py-2.5 ",
  };
  return (
    <button
      className={`rounded md-2 flex justify-center items-center default:cursor-not-allowed disabled:cursor-not-allowed disabled:opacity:70 ${variants[variant]} ${sizes[size]} ${className} `}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
