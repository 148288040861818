import React from "react";
import MyCard from "../../components/common/MyCard";
import HorizontalLinearStepper from "../../components/Stepper";
import Welcome from "../signin/Welcome";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import axiosInstance from "../../axios/axiosInstance";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import Swal from "sweetalert2";

const Register = () => {
  const [isStepValid, setIsStepValid] = React.useState([false, false, false]);
  const [errorMessages, setErrorMessages] = React.useState(["", "", ""]);
  const [myStep, setMyStep] = React.useState(0);
  const [currentStepPayload, setCurrentStepPayload] = React.useState({});
  const [isMobileVerified, setIsMobileVerified] = React.useState(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [requestSession, setRequestSession] = React.useState("");

  const steps = [
    {
      label: "Get Started",
      content: (
        <StepOne
          validate={(isValid, errorMessage, formData) =>
            handleValidation(0, isValid, errorMessage, formData)
          }
          initVals={formData[0]}
          setIsMobileVerified={setIsMobileVerified}
          setIsEmailVerified={setIsEmailVerified}
        />
      ),
    },
    {
      label: "Business Information",
      content: (
        <StepTwo
          validate={(isValid, errorMessage, formData) =>
            handleValidation(1, isValid, errorMessage, formData)
          }
          initVals={formData[1]}
          contactNumber={formData[0]?.company_contact_number}
        />
      ),
    },
    {
      label: "Personal Information",
      content: (
        <StepThree
          validate={(isValid, errorMessage, formData) =>
            handleValidation(2, isValid, errorMessage, formData)
          }
          initVals={formData[2]}
        />
      ),
    },
  ];

  const handleValidation = (stepIndex, isValid, errorMessage, formData) => {
    const updatedValidity = [...isStepValid];
    const updatedErrors = [...errorMessages];

    updatedValidity[stepIndex] = isValid;
    updatedErrors[stepIndex] = errorMessage;
    // console.log("updatedValidity: ", updatedValidity);
    // console.log("formData: ", formData);
    setIsStepValid(updatedValidity);
    setErrorMessages(updatedErrors);
    setCurrentStepPayload(formData);
  };

  // If user inputs data in each step save the data  in formData state to preserve and reset back
  const handlePreserveData = (activeStep, currentStepPayload) => {
    setFormData((formData) => ({
      ...formData,
      [activeStep]: currentStepPayload,
    }));
  };

  // handle saving data into the database
  const handleSaveData = async (url, payload, activeStep) => {
    try {
      const response = await axiosInstance.post(url, payload);
      if (response.data.status == 200 || response.data.status == 201) {
        if (activeStep === 0) setRequestSession(response.data.request_session);
        Swal.fire({
          title: "success",
          text: "Registered Successfully",
          icon: "success",
          confirmButtonText: "Cool",
        });
      } else {
        alert("Something went wrong", response.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleNext = (activeStep) => {
    // Perform your AJAX operations here based on the activeStep
    console.log(`Performing AJAX operation for step: ${activeStep}`);
    console.log(currentStepPayload);
    setMyStep(activeStep);
    // Save the current step data into the formdata
    handlePreserveData(activeStep, currentStepPayload);

    if (!isStepValid[myStep]) {
      alert("Validation Failed..!! Click OK to  proceed");
      // return false;
    }
    switch (activeStep) {
      case 0:
        // Perform AJAX operation for Step 1
        console.log("Submitting data for Step 1");
        handleSaveData(
          "/company/register/start",
          currentStepPayload,
          activeStep
        );
        setMyStep(myStep + 1);
        break;
      case 1:
        // Perform AJAX operation for Step 2
        console.log("Submitting data for Step 2");
        setMyStep(myStep + 1);
        break;
      case 2:
        // Perform AJAX operation for Step 3
        const finalPayload = {
          ...formData[1],
          ...currentStepPayload,
          request_session: requestSession,
        };
        handleSaveData("/company/register/complete", finalPayload, activeStep);
        setMyStep(myStep + 1);
        break;
      default:
        break;
    }
  };

  const handleBack = (activeStep) => {
    console.log("-------handling back-------");
    console.log(activeStep);
    console.log(currentStepPayload);
    handlePreserveData(activeStep, currentStepPayload);
  };

  return (
    <>
      <div className="flex">
        <Welcome />
        <ErrorBoundary>
          <MyCard title="Register Your Organisation" width={1400}>
            <HorizontalLinearStepper
              steps={steps}
              onNext={handleNext}
              onBack={handleBack}
              nextDisabled={
                isStepValid[myStep] && isMobileVerified && isEmailVerified
              }
            />
          </MyCard>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default Register;
