import React, { useState } from "react";
import TreeViewComponent from "../../components/common/TreeViewComponent";
//import MyButton from "../../components/tailwind/MyButton";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import AddBranch from "./AddBranch";

const sampleData = [
  {
    id: "1",
    name: "Brigade Group",
    children: [
      {
        id: "2",
        name: "BG Rajajinagar Branch",
        children: [
          { id: "3", name: "RR Branch 1" },
          { id: "4", name: "RR Branch 2" },
        ],
      },
      { id: "5", name: "RR Branch" },
    ],
  },
  {
    id: "6",
    name: "BG Vijayanagar Branch",
    children: [
      {
        id: "7",
        name: "VJ Branch 1",
        children: [{ id: "8", name: "Sub Branch VR" }],
      },
    ],
  },
];

const Branch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col md:w-[1200px] sm:w-[600px]  p-5 m-2 ">
      <div className="flex justify-end py-3">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          Add Branch
        </Button>
      </div>

      <div className="flex justify-start items-start bg-[#ffffff] rounded-2xl shadow-xl">
        <div className="flex flex-col gap-5 p-5">
          <h1 className="text-2xl font-bold mb-4">Branches</h1>
          {sampleData.map((item) => (
            <TreeViewComponent
              key={item.id}
              // id={item.id}
              node={item}
              parentbranchkey="name"
              childbranchkey="children"
            />
          ))}
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <AddBranch onClose={() => setIsModalOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};

export default Branch;
