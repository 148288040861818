import React, { useState } from "react";
import GeoFencing from "./GeoFencing";
import DeptDesn from "./DeptDesn";
import RoleRight from "./RoleRight";
import AttnConfi from "./attendane_configuration/AttnConfi";
import { Button } from "@mui/material";

const Configuration = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    geoFencing: { radius: "", lat: "", lng: "" },
    deptDesn: { departments: [] },
    roleright: { roles: [] },
    attnconfi: {
      groups: [],
    },
    // Other steps will be added similarly
  });
  const [showPopup, setShowPopup] = useState(false);

  // Handler to update data for each step
  const handleStepDataChange = (stepName, data) => {
    setFormData((prev) => ({
      ...prev,
      [stepName]: data,
    }));
  };

  // Handler for navigating between steps
  const goToStep = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  // Handler to save data for the current step
  const handleSave = (stepName) => {
    console.log(`Data saved for ${stepName}:`, formData[stepName]);
    if (stepName === "step5") {
      // If final step, show the success popup
      setShowPopup(true);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <GeoFencing
            formData={formData.geoFencing}
            onStepDataChange={(data) =>
              handleStepDataChange("geoFencing", data)
            }
            onSave={() => handleSave("geoFencing")}
          />
        );
      case 1:
        return (
          <DeptDesn
            formData={formData.deptDesn}
            onStepDataChange={(data) => handleStepDataChange("deptDesn", data)}
            onSave={() => handleSave("deptDesn")}
          />
        );

      case 2:
        return (
          <RoleRight
            formData={formData.roleright}
            onStepDataChange={(data) => handleStepDataChange("roleright", data)}
            onSave={() => handleSave("roleright")}
          />
        );
      case 3:
        return (
          <AttnConfi
            formData={formData.attnconfi}
            onStepDataChange={(data) => handleStepDataChange("attnconfi", data)}
            onSave={() => handleSave("attnconfi")}
          />
        );
      // Add other steps here (e.g., Step 3, Step 4, etc.)
      default:
        return null;
    }
  };

  return (
    <div className="w-full p-6">
      <h1 className="text-2xl font-semibold mb-4">Configuration</h1>

      {/* Tab Navigation */}
      <div className="flex gap-x-5 border-b mb-4">
        {[
          "GeoFencing",
          "Department & Designation",
          "Roles & Responsibilities",
          "General Configuration",
          "Holiday Configuration",
        ].map((step, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${
              index === currentStep
                ? "border-b-2 border-color1 font-semibold"
                : ""
            }`}
            onClick={() => goToStep(index)}
          >
            {step}
          </button>
        ))}
      </div>

      {/* Render Current Step */}
      <div className="mt-6">{renderStep()}</div>

      {/* Navigation buttons */}
      <div className="flex justify-between mt-6">
        <div className="flex justify-start">
          {currentStep > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
        </div>
        <div className="flex justify-end w-full">
          {/* Display Save button in Step 5 */}
          {currentStep === 4 && (
            <Button
              onClick={() => handleSave("step5")}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
        </div>
        <div className="flex justify-end">
          {currentStep < 4 && (
            <Button
              onClick={() => goToStep(currentStep + 1)}
              variant="contained"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Save & Continue
            </Button>
          )}
        </div>
      </div>

      {/* Success Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-semibold text-center">
              Configuration Successfully Completed
            </h2>
            <div className="mt-4 text-center">
              <Button
                onClick={() => setShowPopup(false)}
                variant="outlined"
                color="primary"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Configuration;
