import { createContext } from "react";
import { MyRoutes } from "./router";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignIn } from "./pages/signin";
import Register from "./pages/register/Register";
// import Header from "./pages/header";
import MyDrawer from "./components/layout/MyDrawer";
import PreRegister from "./pages/register/PreRegister";
import LandingPage from "./pages/landing/LandingPage";
import NotFound from "./pages/NotFound";

const MyContext = createContext();

const Layout = () => (
  <section className="main flex">
    <div>
      <MyDrawer />
    </div>
    <div component="main" sx={{ flexGrow: 1, p: 3 }} className="w-[100%]">
      <div className="space"></div>
      <MyRoutes />
    </div>
  </section>
);

function App() {
  const values = {};

  return (
    <>
      <BrowserRouter>
        <MyContext.Provider value={values}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/preregister" element={<PreRegister />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/app/*" element={<Layout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MyContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
