import React from "react";

import { MdClose } from "react-icons/md";
import {
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import MyCard from "../../../components/common/MyCard";
import ShiftManager from "./ShiftManager";

const GroupForm = ({
  groupData,
  checkInCriteria,
  errorMessage,
  isEditing,
  onGroupDataChange,
  onCheckInCriteriaChange,
  onStaticTimeChange,
  onTrackingFrequencyChange,
  onTrackDeviceStaticChange,
  onSubmit,
  onClose,
}) => {
  return (
    <div className="no-scrollbar overflow-y-scroll">
      <MyCard
        title={
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">
              {isEditing ? "Edit Group" : "Create Group"}
            </h2>
            <IconButton onClick={onClose}>
              <MdClose />
            </IconButton>
          </div>
        }
      >
        {/* Group Information Section */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 border-b pb-4">
          <TextField
            label="Group Name"
            name="groupName"
            value={groupData.groupName}
            onChange={onGroupDataChange}
            required
            fullWidth
          />
          <TextField
            label="Branch"
            name="branch"
            value={groupData.branch}
            onChange={onGroupDataChange}
            fullWidth
          />
          <TextField
            label="Department"
            name="department"
            value={groupData.department}
            onChange={onGroupDataChange}
            fullWidth
          />
          <TextField
            label="Designations"
            name="designations"
            value={groupData.designations}
            onChange={onGroupDataChange}
            fullWidth
          />
          <TextField
            label="Include Employees"
            name="includeEmployees"
            value={groupData.includeEmployees}
            onChange={onGroupDataChange}
            fullWidth
          />
          <TextField
            label="Exclude Employees"
            name="excludeEmployees"
            value={groupData.excludeEmployees}
            onChange={onGroupDataChange}
            fullWidth
          />
        </div>
      </MyCard>

      {/* Check-In Criteria Card */}
      <MyCard title="Check-In Criteria">
        <div className="space-X-4 ">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkInCriteria.officeCheckIn}
                onChange={onCheckInCriteriaChange}
                name="officeCheckIn"
                fullWidth
              />
            }
            label="Enable Office Check-In"
          />
          {checkInCriteria.officeCheckIn && (
            <div className="flex  items-center space-x-4 ">
              <FormControl className="flex-1">
                <InputLabel>Select Static Time</InputLabel>
                <Select
                  value={checkInCriteria.staticTime}
                  onChange={onStaticTimeChange}
                >
                  <MenuItem value="">Not Required</MenuItem>
                  <MenuItem value="1 hour">1 Hour</MenuItem>
                  <MenuItem value="2 hours">2 Hours</MenuItem>
                  <MenuItem value="3 hours">3 Hours</MenuItem>
                  <MenuItem value="4 hours">4 Hours</MenuItem>
                  <MenuItem value="5 hours">5 Hours</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                className="flex-1"
                control={
                  <Checkbox
                    checked={checkInCriteria.blockEmployee}
                    onChange={onCheckInCriteriaChange}
                    name="blockEmployee"
                  />
                }
                label="Block Employee If Outside Premises"
              />
            </div>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={checkInCriteria.fieldCheckIn}
                onChange={onCheckInCriteriaChange}
                name="fieldCheckIn"
              />
            }
            label="Enable Field Check-In"
          />
          {checkInCriteria.fieldCheckIn && (
            <div className="flex flex-row space-x-4">
              <FormControl fullWidth>
                <InputLabel>How Often to Track</InputLabel>
                <Select
                  value={checkInCriteria.trackingFrequency}
                  onChange={onTrackingFrequencyChange}
                >
                  <MenuItem value="">Not Required</MenuItem>
                  <MenuItem value="15 minutes">15 Minutes</MenuItem>
                  <MenuItem value="30 minutes">30 Minutes</MenuItem>
                  <MenuItem value="45 minutes">45 Minutes</MenuItem>
                  <MenuItem value="1 hour">1 Hour</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Track Device Static</InputLabel>
                <Select
                  value={checkInCriteria.trackDeviceStatic}
                  onChange={onTrackDeviceStaticChange}
                >
                  <MenuItem value="">Not Required</MenuItem>
                  <MenuItem value="1 hour">1 Hour</MenuItem>
                  <MenuItem value="2 hours">2 Hours</MenuItem>
                  <MenuItem value="3 hours">3 Hours</MenuItem>
                  <MenuItem value="4 hours">4 Hours</MenuItem>
                  <MenuItem value="5 hours">5 Hours</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={checkInCriteria.faceValidation}
                onChange={onCheckInCriteriaChange}
                name="faceValidation"
              />
            }
            label="Enable Face Validation"
          />
        </div>
      </MyCard>

      {/* Error Message */}
      {errorMessage && (
        <p className="text-red-500 text-sm mb-2 pl-5">{errorMessage}</p>
      )}

      {/* Submit Button */}
      <div className="flex justify-end">
        <Button onClick={onSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </div>
      <ShiftManager />
    </div>
  );
};

export default GroupForm;
