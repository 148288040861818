import { useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import MyCard from "../../components/common/MyCard";
import { TextField, Box, Button } from "@mui/material";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Example",
      data: [65, 59, 80, 81, 56, 55, 100],
      fill: true,
      backgroundColor: [
        "rgba(0, 50, 150, 0.6)", // Darker shade
        "rgba(0, 60, 180, 0.6)", // Similar blue, slightly lighter
        "rgba(0, 70, 210, 0.6)", // Lighter blue
        "rgba(255, 204, 0, 0.6)", // Complementary yellow
        "rgba(0, 150, 150, 0.6)", // Teal
        "rgba(199, 0, 0, 0.6)", // Bold red
        "rgba(0, 200, 0, 0.6)", // Bright green
      ],
      // borderColor: "#003296",
    },
  ],
};
const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const Dashboard = () => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    // Destroy the existing chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Create a new chart instance
    chartRef.current = new Chart(ctx, data);

    // Cleanup function to destroy the chart on component unmount
    return () => {
      chartRef.current.destroy();
    };
  }, []); // Empty dependency array to run only on mount/unmount

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <div className="flex">
        <MyCard title="Emplyees Branch Wise" width={550}>
          <Bar data={data} options={options} ref={canvasRef} />
        </MyCard>
        <MyCard title="Late comers count from last one week" width={550}>
          <Line data={data} options={options} ref={canvasRef} />
        </MyCard>
      </div>
      <div className="flex">
        <MyCard title="Emplyees Department Wise" width={550}>
          <Bar data={data} options={options} ref={canvasRef} />
        </MyCard>
        <MyCard title="Emplyees By Designation" width={550}>
          <Bar data={data} options={options} ref={canvasRef} />
        </MyCard>
      </div>
      <div className="flex">
        <MyCard title="Emplyees Department Wise" width={550}>
          <Doughnut data={data} options={options} ref={canvasRef} />
        </MyCard>
      </div>
    </div>
  );
};

export default Dashboard;
