import React from "react";
import logo from "../../assets/Group2.png";

const AboutUs = (props) => {
  return (
    <div
      id="aboutus"
      className="flex flex-col md:flex-row w-full mt-5 mb-10 scroll-offset"
    >
      <div className="flex justify-center items-center  md:w-1/2 my-5">
        <img src={logo} alt="About Us" className="w-4/5 h-auto ml-4 md:ml-6" />
      </div>

      <div className="flex flex-col items-center text-color2 justify-center md:w-1/2">
        <div className="w-10/12 text-center  md:text-left">
          <h1 className="text-4xl font-semibold  mt-3">
            About <u>us</u>
          </h1>
          <p className="text-base font-thin gap-y-5 mt-4 mb-5 leading-loose">
            The idea of Klockmate was constituted on the base line of filling
            the void and solving the problems faced by enterprises in day-to-day
            business in managing Human Resources. The life-altering pandemic has
            given rise to the demand to further modernize the cutting-edge
            technology we possess today. It was about time that we modernize the
            traditional form of tracking attendance, paying salary and computing
            the taxes that come along with it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
