import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DynamicForm = ({ fields }) => {
  const initialValues = fields.reduce((acc, field) => {
    acc[field.name] = field.initialValue || "";
    return acc;
  }, {});

  const validationSchema = Yup.object(
    fields.reduce((acc, field) => {
      if (field.validation) {
        acc[field.name] = field.validation;
      }
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            {fields.map((field) => {
              switch (field.type) {
                case "text":
                  return (
                    <div key={field.name}>
                      <TextField
                        name={field.name}
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={values[field.name]}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched(field.name)}
                        error={
                          touched[field.name] && Boolean(errors[field.name])
                        }
                        helperText={touched[field.name] && errors[field.name]}
                      />
                    </div>
                  );

                case "select":
                  return (
                    <div key={field.name}>
                      <Autocomplete
                        options={field.options || []} // Ensure data is not null or undefined
                        getOptionLabel={(option) => option.label}
                        name={field.name}
                        onChange={(event, newValue) => {
                          // Set the field value
                          setFieldValue(
                            field.name,
                            newValue ? newValue.value : ""
                          );
                          // Mark the field as touched
                          setFieldTouched(field.name, true);
                        }}
                        onBlur={() => setFieldTouched(field.name, true)}
                        value={
                          field.options.find(
                            (option) => option.value === values[field.name]
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={field.label}
                            variant="outlined"
                            margin="normal"
                            error={
                              touched[field.name] && Boolean(errors[field.name])
                            }
                            helperText={
                              touched[field.name] && errors[field.name]
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  );

                case "radio":
                  return (
                    <FormControl
                      component="fieldset"
                      key={field.name}
                      margin="normal"
                    >
                      <FormLabel component="legend">{field.label}</FormLabel>
                      <RadioGroup
                        name={field.name}
                        value={values[field.name]}
                        onChange={handleChange}
                      >
                        {field.options.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                      {touched[field.name] && errors[field.name] ? (
                        <Typography variant="caption" color="error">
                          {errors[field.name]}
                        </Typography>
                      ) : null}
                    </FormControl>
                  );

                case "checkbox":
                  return (
                    <div key={field.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={field.name}
                            checked={values[field.name]}
                            onChange={handleChange}
                          />
                        }
                        label={field.label}
                      />
                      {touched[field.name] && errors[field.name] ? (
                        <Typography variant="caption" color="error">
                          {errors[field.name]}
                        </Typography>
                      ) : null}
                    </div>
                  );

                case "date":
                  return (
                    <div key={field.name}>
                      <DatePicker
                        name={field.name}
                        label={field.label}
                        value={values[field.name]}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  );

                default:
                  return null;
              }
            })}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default DynamicForm;
