import React, { Children } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme, width }) => ({
  boxShadow: theme.shadows[5], // Add shadow
  borderRadius: "8px", // Optional: customize border radius
  margin: "20px", // Optional: margin around the card
  width: width || "100%",
}));

const MyCard = ({ title, children, width = 1000 }) => {
  return (
    <StyledCard width={width}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "h6",
        }} // Customize header typography
      />
      <Divider style={{ marginBottom: "8px" }} />
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default MyCard;
