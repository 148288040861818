import React from "react";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "../pages/dashboard";
import { Stats } from "../pages/stats";
import { Branch } from "../pages/branch";
import { RegisteredUsers } from "../pages/register";
import LandingPage from "../pages/landing/LandingPage";
import Configuration from "../pages/configuration/Configuration";
import NotFound from "../pages/NotFound";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/stats" element={<Stats />} />
      <Route path="/settings/branches" element={<Branch />} />
      <Route path="/settings/regusers" element={<RegisteredUsers />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/settings/configuration" element={<Configuration />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MyRoutes;
