import React, { useState, useRef, useEffect } from "react";
import MyButton from "../../components/tailwind/MyButton";
import Welcome from "./Welcome";
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();
  const countryCodes = [
    { code: "+91", label: "Ind" },
    { code: "+1", label: "Usa" },
    { code: "+44", label: "Uk" },
    { code: "+81", label: "Jap" },

    // Add more country codes as needed
  ];
  const [otp, setOtp] = useState(Array(4).fill(""));
  const inputRefs = useRef([]);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // 30 seconds for the timer
  const [mobileNumber, setMobileNumber] = useState(""); // State to store mobile number
  const [showOtpInputs, setShowOtpInputs] = useState(false); // show the otp field
  const [errorMessage, setErrorMessage] = useState(""); // State for error message of mobile digit
  const [countryCode, setCountryCode] = useState("+91"); // state to manage the country code
  // Check if mobile number is valid
  const isMobileNumberValid = /^\d{10}$/.test(mobileNumber);

  useEffect(() => {
    let interval = null;
    if (resendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendDisabled, timer]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Move to the next input
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move back to the previous input if current is empty
      inputRefs.current[index - 1].focus();
    }
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value); // Update mobile number state
    setErrorMessage(""); // Clear error message on input change
  };

  const sendOTP = async () => {
    try {
      const response = await axiosInstance.post("/company/mobile-otp", {
        contact_number: mobileNumber,
      });
      //   setData(response.data);
      console.log("OTP sent successfully:", response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleMobileNumberSubmit = () => {
    // Logic to send the OTP to the mobile number can go here

    // Check if mobile number is valid
    if (!isMobileNumberValid) {
      setErrorMessage("Please enter a valid 10-digit mobile number.");
      return;
    } else {
      setShowOtpInputs(true); // Show OTP input boxes after mobile number submission
      sendOTP();
    }
  };

  const handleResend = () => {
    // Reset OTP and timer
    setOtp(Array(4).fill(""));
    inputRefs.current[0].focus();
    setResendDisabled(true);
    setTimer(30); // Reset timer to 30 seconds
    sendOTP();
  };

  return (
    <div className="flex">
      <Welcome />

      <div className="flex w-screen h-screen justify-center items-center">
        <div className="flex flex-col gap-5 p-5 bg-[#ffffff] rounded-2xl shadow-xl">
          <div className=" gap-5 p-10 flex flex-col justify-between items-stretch">
            <h6 className="flex justify-center text-5xl text-transparent bg-clip-text bg-gradient-to-r to-theme-blue from-sky-400 font-semibold mb-2">
              Welcome back!
            </h6>
            <label
              className=" text-gray-700 text-sm font-medium tracking-wider flex justify-start "
              for="otp"
            >
              Mobile number
            </label>
            <div class="mb-2 flex flex-row justify-between items-center gap-2  ">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
              >
                {countryCodes.map((code) => (
                  <option key={code.code} value={code.code} className="text-sm">
                    {code.label} ({code.code})
                  </option>
                ))}
              </select>

              <input
                class="h-12 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline tracking-wider"
                id="otp"
                placeholder="Enter Mobile Number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
            </div>

            {errorMessage && (
              <p className="text-red-500 text-sm mb-2 flex justify-center">
                {errorMessage}
              </p> // Error message
            )}

            <MyButton
              onClick={handleMobileNumberSubmit}
              //disabled={!isMobileNumberValid}
              //className={`${!isMobileNumberValid ? "cursor-not-allowed" : ""}`}
            >
              Send OTP
            </MyButton>
          </div>
          {showOtpInputs && (
            <div className="flex flex-col justify-between items-stretch">
              <div class="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                {/* <label class="block text-gray-700 text-sm font-bold mb-2" for="otp">
              OTP:
            </label> */}
                <h1 class=" text-2xl text-transparent bg-clip-text bg-gradient-to-r to-theme-blue from-sky-400 font-semibold mb-2 tracking-wider">
                  Mobile Number Verification
                </h1>
                <p className=" text-gray-700 text-sm font-normal tracking-wider flex justify-start">
                  Enter the 4-digit verification code that has been sent to your
                  mobile number.
                </p>

                <div className="flex justify-center">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      value={digit}
                      onChange={(e) => handleChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="w-12 h-12 text-center border rounded-md shadow-sm p-2 m-2"
                      maxLength="1"
                      autoFocus={index === 0}
                      placeholder=""
                    />
                  ))}
                </div>
                <div className="mt-2">
                  {resendDisabled ? (
                    <span className="text-gray-700 text-sm font-normal tracking-wider mb-2 ">
                      Resend in {timer} seconds
                    </span>
                  ) : (
                    <button
                      onClick={handleResend}
                      className="text-gray-700 text-sm font-normal tracking-wider hover:underline mb-2"
                    >
                      Resend OTP
                    </button>
                  )}
                </div>
                <div className="flex flex-col justify-between items-stretch">
                  <MyButton onClick={() => navigate("/app/dashboard")}>
                    Sign In
                  </MyButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
