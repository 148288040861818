import React, { useState } from "react";
import mail from "../../assets/landingpage/Mail.png";
import facebook from "../../assets/landingpage/Facebook.png";
import instagram from "../../assets/landingpage/Instagram.png";
import linkedin from "../../assets/landingpage/Linkedin.png";
import location from "../../assets/landingpage/Location.png";
import mobile from "../../assets/landingpage/Mobile.png";
import twitter from "../../assets/landingpage/Twitter.png";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here to handle the API
    console.log("Name:", name);

    // Clear the form\
    setName("");
    setEmail("");
    setMessage("");
    setNumber("");
  };

  return (
    <div id="contactus" className="w-full p-10 scroll-offset">
      <div className="px-4 py-8 bg-gray-50 shadow-lg rounded-lg p-5 ">
        <h1 className="text-4xl font-semibold text-color2 px-5 border-b border-gray-300 pb-6 ">
          Contact <u>Us</u>
        </h1>
        <div className="flex md:flex-row flex-col">
          <div className="flex flex-col w-full tracking-wide px-10 pt-10  ">
            <div className="w-full mb-8 lg:mb-0 py-5 ">
              <h1 className="text-xl font-bold text-teal-700 my-3">
                Get a quote
              </h1>
              <p className="text-color2 mb-10 w-full ">
                Fill up the form and our team will get back <br />
                to you within 24 hours.
              </p>

              <div className="flex items-center mt-5 px-5 mb-7">
                <img src={mobile} alt="phone" className="mr-3 h-7 w-6" />
                <p className="text-color2">+91 93424 99299</p>
              </div>
              <div className="flex items-center mt-4 px-5 mb-7">
                <img src={mail} alt="email" className="mr-3 h-6 w-8" />
                <p className="text-color2">maplebell.official@gmail.com</p>
              </div>
              <div className="flex items-center mt-4 px-5 mb-10">
                <img src={location} alt="location" className="mr-3 h-9 w-7" />
                <p className="text-gray-600">
                  #363, 19th Main Road, 1st Block,
                  <br /> Rajajinagar, Bengaluru - 560010
                </p>
              </div>
              <div className="flex space-x-8 mt-4 px-5 h-8">
                <img src={instagram} alt="instagram" width="30" />
                <img src={facebook} alt="facebook" width="20" />
                <img src={twitter} alt="twitter" width="30" />
                <img src={linkedin} alt="linkedin" width="30" />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center p-5">
            <Card className="w-full shadow-md p-4">
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Your Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    size="small"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    size="small"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={number}
                    size="small"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    size="large"
                    className="mt-4"
                    style={{ backgroundColor: "#0d6e78", color: "white" }}
                  >
                    SEND
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
