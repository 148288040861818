import React, { useState, useEffect } from "react";
import MyCard from "../../../components/common/MyCard";
import { MdAdd, MdModeEdit, MdClose } from "react-icons/md";
import {
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import GroupView from "./GroupView";
import GroupForm from "./Groupform";

const AttnConfi = ({ formData, onStepDataChange }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [groups, setGroups] = useState(formData?.groups || []);
  const [groupData, setGroupData] = useState({
    groupName: "",
    branch: "",
    department: "",
    designations: "",
    includeEmployees: "",
    excludeEmployees: "",
  });
  const [checkInCriteria, setCheckInCriteria] = useState({
    officeCheckIn: false,
    fieldCheckIn: false,
    faceValidation: false,
    staticTime: "",
    blockEmployee: false,
    trackingFrequency: "", // New field for frequency tracking
    trackDeviceStatic: "", // New field for static device tracking
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (formData?.groups) {
      setGroups(formData.groups);
    }
  }, [formData]);

  const handleOpenPopup = (group = null, index = null) => {
    if (group) {
      setGroupData(group);
      setCheckInCriteria(
        group.checkInCriteria || {
          officeCheckIn: false,
          fieldCheckIn: false,
          faceValidation: false,
          staticTime: "",
          blockEmployee: false,
          trackingFrequency: "",
          trackDeviceStatic: "",
        }
      );
      setIsEditing(true);
      setEditingIndex(index);
    } else {
      setGroupData({
        groupName: "",
        branch: "",
        department: "",
        designations: "",
        includeEmployees: "",
        excludeEmployees: "",
      });
      setCheckInCriteria({
        officeCheckIn: false,
        fieldCheckIn: false,
        faceValidation: false,
        staticTime: "",
        blockEmployee: false,
        trackingFrequency: "",
        trackDeviceStatic: "",
      });
      setIsEditing(false);
      setEditingIndex(null);
    }
    setErrorMessage("");
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGroupData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckInCriteriaChange = (e) => {
    const { name, checked } = e.target;
    setCheckInCriteria((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleStaticTimeChange = (e) => {
    const { value } = e.target;
    setCheckInCriteria((prev) => ({
      ...prev,
      staticTime: value,
    }));
  };

  const handleTrackingFrequencyChange = (e) => {
    const { value } = e.target;
    setCheckInCriteria((prev) => ({
      ...prev,
      trackingFrequency: value,
    }));
  };

  const handleTrackDeviceStaticChange = (e) => {
    const { value } = e.target;
    setCheckInCriteria((prev) => ({
      ...prev,
      trackDeviceStatic: value,
    }));
  };

  const handleSubmit = () => {
    if (!groupData.groupName.trim()) {
      setErrorMessage("Group name is required.");
      return;
    }

    const groupExists = groups.some(
      (group, idx) =>
        group.groupName.toLowerCase() === groupData.groupName.toLowerCase() &&
        idx !== editingIndex
    );

    if (groupExists) {
      setErrorMessage(
        "Group name already exists. Please choose a different name."
      );
      return;
    }

    const newGroup = {
      ...groupData,
      checkInCriteria, // Include the Check-In Criteria for the group
    };
    const updatedGroups = isEditing
      ? groups.map((group, idx) => (idx === editingIndex ? newGroup : group))
      : [...groups, newGroup];

    setGroups(updatedGroups);
    onStepDataChange({ ...formData, groups: updatedGroups });
    setIsPopupOpen(false); // Close the popup after submission
  };

  return (
    <div className="space-y-2 p-4 ">
      {/* Add Group Button */}
      <div className="flex justify-end mr-5">
        <Button
          variant="contained"
          color="primary"
          startIcon={<MdAdd />}
          onClick={() => handleOpenPopup()}
          className="float-right"
        >
          Add Group
        </Button>
      </div>
      {/* Groups Card View */}
      <div className="flex flex-col space-y-4">
        {groups.map((group, index) => (
          <GroupView
            key={index}
            group={group}
            index={index}
            handleOpenPopup={handleOpenPopup} // Pass down the handleOpenPopup function
          />
        ))}
      </div>
      <Modal
        open={isPopupOpen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="flex flex-col items-center justify-center h-full w-full ">
          <div className="rounded-2xl shadow-xl bg-white p-4">
            <GroupForm
              groupData={groupData}
              checkInCriteria={checkInCriteria}
              errorMessage={errorMessage}
              isEditing={isEditing}
              onGroupDataChange={handleInputChange}
              onCheckInCriteriaChange={handleCheckInCriteriaChange}
              onStaticTimeChange={handleStaticTimeChange}
              onTrackingFrequencyChange={handleTrackingFrequencyChange}
              onTrackDeviceStaticChange={handleTrackDeviceStaticChange}
              onSubmit={handleSubmit}
              onClose={() => handleClosePopup(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AttnConfi;
