import React from "react";
import attendance from "../../assets/Attendance.png";
import shift from "../../assets/shiftManagement.png";
import DailyStats from "../../assets/DailyStats.png";
import Leave from "../../assets/Leave.png";
import TimeSheets from "../../assets/TimeSheets.png";
import EDocument from "../../assets/EDocument.png";
import Notifications from "../../assets/Notifications.png";
import SalaryCalculations from "../../assets/SalaryCalculations.png";
import GenerationReports from "../../assets/GenerationReports.png";
import Salarypayments from "../../assets/Salarypayments.png";
import PettyCash from "../../assets/PettyCash.png";
import PaySlips from "../../assets/PaySlips.png";

const features = [
  {
    title: "Attendance Management",
    description: ["Office checkin", "Remote checkin"],
    icon: attendance,
  },
  {
    title: "Shift Management",
    description: ["Change Shift", "Allocate Multiple Shift", "Operate 24/7"],
    icon: shift,
  },
  {
    title: "Daily Stats",
    description: ["Real Time", "Location wise"],
    icon: DailyStats,
  },
  {
    title: "Leave Management",
    description: [
      "Applying for leaves",
      "Leave Record",
      "Approval/Rejection/Revoking of leaves",
    ],
    icon: Leave,
  },
  {
    title: "Time Sheets",
    description: ["Daily", "Weekly", "Monthly"],
    icon: TimeSheets,
  },
  {
    title: "E- Documents",
    description: ["Go Paperless", "Maintain All Records", "Easy Access"],
    icon: EDocument,
  },
  {
    title: "Notifications",
    description: ["Create", "Schedule", "Share"],
    icon: Notifications,
  },
  {
    title: "Salary Calculations",
    description: ["Real Time", "Over Time (OT)", "EBonus", "Incentives"],
    icon: SalaryCalculations,
  },
  {
    title: "Generation of Reports",
    description: [
      "Attendance Report",
      "Log Report",
      "Leave Report",
      "Salary Report",
    ],
    icon: GenerationReports,
  },
  {
    title: "Salary Payments",
    description: ["Individual", "Bulk", "Instant (Within Minutes)"],
    icon: Salarypayments,
  },
  {
    title: "Petty Cash Payments",
    description: [
      "Instant Transfer",
      "Individual Reports",
      "Monthly Statements",
    ],
    icon: PettyCash,
  },
  {
    title: "Pay Slips",
    description: ["Auto generation", "Instant Availability", "Customisable"],
    icon: PaySlips,
  },
];

const Features = () => {
  return (
    <div id="ourfeatures" className="w-full p-10 scroll-offset">
      <div className="px-4 py-8 bg-gray-50 shadow-lg rounded-lg p-5">
        <h1 className="text-4xl font-semibold text-color2 mb-5 px-5 border-b border-gray-300 pb-6">
          Our <u>Features</u>
        </h1>

        {/* Feature Cards Grid */}
        <div className="p-10 grid gap-x-28 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-3 gap-y-1 rounded-lg shadow-md flex flex-col items-center text-center"
            >
              <img
                src={feature.icon}
                alt={feature.title}
                className="w-10 h-10 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2 text-color2">
                {feature.title}
              </h3>
              {feature.description.map((line, idx) => (
                <p key={idx} className="text-color2 text-sm">
                  {line}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
