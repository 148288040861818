import React from "react";
import MyButton from "../../components/tailwind/MyButton";
import logo from "../../assets/klockmatehome.png";
import { useNavigate } from "react-router-dom";

const PreRegister = () => {
  const navigate = useNavigate();

  return (
    <div className="flex w-screen h-screen justify-center items-center">
      <div className="flex flex-col w-[500px] gap-5 p-10  bg-[#ffffff] rounded-2xl shadow-xl justify-stretch ">
        {/* md:w-1/2 xl:w-2/5 2xl:w-2/5 3xl:w-1/3 md:h-1/2 xl:h-2/5 2xl:h-2/5 3xl:h-1/3 gap-5 p-5 md:p-10 2xl:p-12 3xl:p-14 */}
        <div className="flex justify-center ">
          <img src={logo} width="200" alt="Logo" />
        </div>
        <MyButton onClick={() => navigate("/register")}>
          Register my Enterprise
        </MyButton>
        <MyButton onClick={() => navigate("/signin")}>Sign In</MyButton>
      </div>
    </div>
  );
};

export default PreRegister;
