import React from "react";
import Navbar from "./NavBar";
import AboutUs from "./AboutUs";
import Home from "./Home";
import Flowchart from "./FlowChart";
import Features from "./Features";
import Contactus from "./Contactus";
const LandingPage = () => {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Home />
      <AboutUs />
      <Features />
      <Flowchart />
      <Contactus />
    </div>
  );
};

export default LandingPage;
