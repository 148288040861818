import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import MyCard from "../../../components/common/MyCard";

const ShiftManager = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [shiftDetails, setShiftDetails] = useState({
    startTime: new Date(),
    endTime: new Date(),
    bufferTime: "",
    breaks: [],
    overtime: { otHours: "", otPay: "", otType: "" },
    weekoffDay: "",
    customShiftDetails: "",
  });

  const [currentBreak, setCurrentBreak] = useState({
    purpose: "",
    startTime: new Date(),
    endTime: new Date(),
  });
  const [isEditingBreak, setIsEditingBreak] = useState(false);

  const [isEditingOT, setIsEditingOT] = useState(false);
  const [isAddingWeekoff, setIsAddingWeekoff] = useState(false);
  const [isAddingCustomShift, setIsAddingCustomShift] = useState(false);

  // Function to open and close the popup
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  const handleShiftInputChange = (e) => {
    const { name, value } = e.target;
    setShiftDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleBreakInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentBreak((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddBreak = () => {
    setShiftDetails((prev) => ({
      ...prev,
      breaks: [...prev.breaks, currentBreak],
    }));
    setCurrentBreak({ purpose: "", startTime: "", endTime: "" });
    setIsEditingBreak(false);
  };

  const handleAddOT = () => {
    setIsEditingOT(true);
  };

  const handleAddOTDetails = () => {
    setIsEditingOT(false);
    console.log("Overtime details added:", shiftDetails.overtime);
  };

  const handleAddWeekoff = () => {
    setIsAddingWeekoff(true);
  };

  const handleWeekoffChange = (e) => {
    setShiftDetails((prev) => ({ ...prev, weekoffDay: e.target.value }));
    setIsAddingWeekoff(false);
  };

  const handleAddCustomShift = () => {
    setIsAddingCustomShift(true);
  };

  const handleCustomShiftChange = (e) => {
    setShiftDetails((prev) => ({
      ...prev,
      customShiftDetails: e.target.value,
    }));
    setIsAddingCustomShift(false);
  };

  const handleSubmit = () => {
    console.log("Shift details submitted:", shiftDetails);
    handleClosePopup();
  };

  return (
    <div>
      {/* Buttons for Add Shift, Add Weekoff, Add Custom Shift */}
      <div className="space-x-4 mb-4">
        <Button variant="contained" color="primary" onClick={handleOpenPopup}>
          Manage Shift
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleAddWeekoff}>
          Add Weekoff
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleAddCustomShift}
        >
          Add Custom Shift
        </Button>
      </div>

      {/* Modal for Shift Management */}
      <Modal open={isPopupOpen} onClose={handleClosePopup}>
        <div className="flex justify-center items-center h-full">
          <div className="w-96 p-4 bg-white rounded-lg shadow-lg">
            <MyCard title="Shift Management">
              {/* Add Shift Form */}
              <div>
                <Grid container spacing={2}>
                  {/* Start Time */}
                  <Grid item xs={12}>
                    <label className="text-sm font-semibold">Start Time</label>
                  </Grid>

                  {/* End Time */}
                  <Grid item xs={12}>
                    <label className="text-sm font-semibold">End Time</label>
                  </Grid>

                  {/* Buffer Time */}
                  <Grid item xs={12}>
                    <TextField
                      label="Buffer Time (mins)"
                      type="number"
                      name="bufferTime"
                      value={shiftDetails.bufferTime}
                      onChange={handleShiftInputChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {/* Buttons to Add Break or OT */}
                <div className="mt-4">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setIsEditingBreak(true)}
                  >
                    Add Break
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleAddOT}
                  >
                    Add OT
                  </Button>
                </div>

                {/* Break Inputs */}
                {isEditingBreak && (
                  <div className="mt-4">
                    <TextField
                      label="Break Purpose"
                      name="purpose"
                      value={currentBreak.purpose}
                      onChange={handleBreakInputChange}
                      fullWidth
                    />
                    <label className="text-sm font-semibold">
                      Break Start Time
                    </label>

                    <label className="text-sm font-semibold">
                      Break End Time
                    </label>

                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddBreak}
                      >
                        Save Break
                      </Button>
                    </div>
                  </div>
                )}

                {/* OT Inputs */}
                {isEditingOT && (
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <InputLabel>OT Hours</InputLabel>
                      <Select
                        value={shiftDetails.overtime.otHours}
                        onChange={(e) =>
                          setShiftDetails((prev) => ({
                            ...prev,
                            overtime: {
                              ...prev.overtime,
                              otHours: e.target.value,
                            },
                          }))
                        }
                      >
                        <MenuItem value={1}>1 Hour</MenuItem>
                        <MenuItem value={2}>2 Hours</MenuItem>
                        <MenuItem value={3}>3 Hours</MenuItem>
                        <MenuItem value={4}>4 Hours</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>OT Pay</InputLabel>
                      <Select
                        value={shiftDetails.overtime.otPay}
                        onChange={(e) =>
                          setShiftDetails((prev) => ({
                            ...prev,
                            overtime: {
                              ...prev.overtime,
                              otPay: e.target.value,
                            },
                          }))
                        }
                      >
                        <MenuItem value="hourly">Hourly</MenuItem>
                        <MenuItem value="fixed">Fixed</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>OT Type</InputLabel>
                      <Select
                        value={shiftDetails.overtime.otType}
                        onChange={(e) =>
                          setShiftDetails((prev) => ({
                            ...prev,
                            overtime: {
                              ...prev.overtime,
                              otType: e.target.value,
                            },
                          }))
                        }
                      >
                        <MenuItem value="regular">Regular</MenuItem>
                        <MenuItem value="double">Double Time</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddOTDetails}
                      >
                        Save OT
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </MyCard>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShiftManager;
