import React from "react";
import logo from "../../assets/Group1.png";

const Home = () => {
  return (
    <>
      <div
        id="home"
        className="px-10 flex sm:flex-row md:flex-row justify-end mb-10 scroll-offset"
      >
        {/* <div className="flex flex-row gap-x-10 items-center  bg-sky-700"> */}
        <div className="flex flex-col gap-y-8 font-semibold items-stretch justify-center tracking-widest animate-slide-in-left ">
          <div className="text-5xl text-color1">DIGITALIZING</div>
          <div className="text-6xl  text-color1">HUMAN</div>
          <div className="text-5xl  text-color2">RESOURCES</div>
        </div>
        <div className="flex justify-center items-center md:w-3/4 sm:w-1/2 my-5">
          <img src={logo} alt="About Us" />
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Home;
