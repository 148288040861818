import React from "react";
import logo from "../../assets/FlowChart.png";
const Flowchart = (props) => {
  return (
    <div id="flowchart" className="w-full p-10 scroll-offset">
      <div className="bg-gray-50 shadow-lg rounded-lg p-5">
        <h1 className="text-4xl font-semibold text-gray-700 mb-5 px-5 border-b border-gray-300 pb-6">
          Flow <u>Chart</u>
        </h1>
        <div className="flex justify-center mb-5">
          <img src={logo} alt="Flowchart" className="w-[70%]" />
        </div>
      </div>
    </div>
  );
};

export default Flowchart;
