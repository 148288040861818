import React from "react";
import { TextField, MenuItem, Link, Box, Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useFetch from "../../hooks/useFetch";

const gender = [
  { code: "male", label: "Male" },
  { code: "female", label: "Female" },
  { code: "other", label: "No to  specify" },
];

const maritalStatus = [
  { code: "single", label: "Single" },
  { code: "married", label: "Married" },
];

const bloodGroup = [
  { id: "A+", disp_name: "A+ (A Positive)" },
  { id: "A-", disp_name: "A- (A Negative)" },
  { id: "B+", disp_name: "B+ (B Positive)" },
  { id: "B-", disp_name: "B- (B Negative)" },
  { id: "AB+", disp_name: "AB+ (AB Positive)" },
  { id: "AB-", disp_name: "AB- (AB Negative)" },
  { id: "O+", disp_name: "O+ (O Positive)" },
  { id: "O-", disp_name: "O- (O Negative)" },
];

const StepThree = ({ validate, initVals }) => {
  const {
    data: qualifications,
    loading: qLoading,
    error: qError,
  } = useFetch("/meta/company-ownership-type");

  const formik = useFormik({
    initialValues: {
      admin_name: initVals ? initVals["admin_name"] : "",
      admin_contact_number: initVals ? initVals["admin_contact_number"] : "",
      admin_gender:
        initVals && typeof initVals === "object" && "admin_gender" in initVals
          ? initVals["admin_gender"]
          : "",
      admin_email: initVals ? initVals["admin_email"] : "",
      admin_blood_group: initVals?.admin_blood_group || "",
      admin_date_of_birth: initVals ? initVals["admin_date_of_birth"] : "",
      admin_residence_address: initVals
        ? initVals["admin_residence_address"]
        : "",
      admin_residence_pincode: initVals
        ? initVals["admin_residence_pincode"]
        : "",
      admin_pan: initVals ? initVals["admin_pan"] : "",
      admin_aadhaar_number: initVals ? initVals["admin_aadhaar_number"] : "",
      admin_uan: initVals ? initVals["admin_uan"] : "",
      admin_marital_status: initVals?.admin_marital_status || "",
      admin_qualification: initVals?.admin_qualification || "",
      admin_designation: initVals ? initVals["admin_designation"] : "",
      admin_department: initVals ? initVals["admin_department"] : "",
      admin_employee_number: initVals ? initVals["admin_employee_number"] : "",
      admin_working_location: initVals
        ? initVals["admin_working_location"]
        : "",
      admin_date_of_joining: initVals ? initVals["admin_date_of_joining"] : "",
      admin_klockmate_referred_by: initVals
        ? initVals["admin_klockmate_referred_by"]
        : "",
    },
    validationSchema: Yup.object({
      admin_name: Yup.string().required("Name is required."),
      admin_contact_number: Yup.number().required(
        "Contact number is required."
      ),
      admin_gender: Yup.string().required("Gender is required."),
      admin_qualification: Yup.number().required("Qualification is required."),
      admin_designation: Yup.string().required("Designation is required."),
      admin_department: Yup.string().required("Department is required."),
      admin_working_location: Yup.string().required(
        "Working location is required."
      ),
    }),
    onSubmit: (values) => {
      // Call the validate function to indicate successful validation
      validate(true, "", values); // Valid form submission
      console.log("Form submitted:", values);
    },
  });

  const handleBlur = (event) => {
    formik.handleBlur(event);
    const { name } = event.target;
    const isValid = !formik.errors[name] && formik.touched[name];

    // Call validate function with the current validation state
    validate(isValid, isValid ? "" : formik.errors[name], formik.values);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              row
            >
              <Grid item size={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  type="text"
                  name="admin_name"
                  value={formik.values.admin_name}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_name &&
                    Boolean(formik.errors.admin_name)
                  }
                  helperText={
                    formik.touched.admin_name && formik.errors.admin_name
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  type="number"
                  name="admin_contact_number"
                  value={formik.values.admin_contact_number}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_contact_number &&
                    Boolean(formik.errors.admin_contact_number)
                  }
                  helperText={
                    formik.touched.admin_contact_number &&
                    formik.errors.admin_contact_number
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  select
                  label="Gender"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={formik.values.admin_gender}
                  onChange={(event) => {
                    const selectedValue = event.target.value; // Get the selected value from the event
                    formik.setFieldValue("admin_gender", selectedValue); // Set the value in Formik
                    console.log(selectedValue); // Log the selected value
                  }}
                  onBlur={formik.handleBlur}
                >
                  {gender.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="admin_email"
                  value={formik.values.admin_email}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_email &&
                    Boolean(formik.errors.admin_email)
                  }
                  helperText={
                    formik.touched.admin_email && formik.errors.admin_email
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  select
                  label="Blood Group"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={formik.values.admin_blood_group}
                  onChange={(event) => {
                    const selectedValue = event.target.value; // Get the selected value from the event
                    formik.setFieldValue("admin_blood_group", selectedValue); // Set the value in Formik
                    console.log(selectedValue); // Log the selected value
                  }}
                  onBlur={formik.handleBlur}
                >
                  {bloodGroup.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.disp_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item size={6}>
                <DatePicker
                  name="admin_date_of_birth"
                  label="Date of Birth"
                  value={formik.values.admin_date_of_birth || null} // Ensure a valid date or null
                  onChange={(date) =>
                    formik.setFieldValue("admin_date_of_birth", date)
                  } // Use setFieldValue to update Formik value
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        formik.touched.admin_date_of_birth &&
                        Boolean(formik.errors.admin_date_of_birth)
                      }
                      helperText={
                        formik.touched.admin_date_of_birth &&
                        formik.errors.admin_date_of_birth
                      }
                    />
                  )}
                  onBlur={formik.handleBlur} // Use Formik's built-in handleBlur
                  fullWidth
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Residence Address"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="admin_residence_address"
                  value={formik.values.admin_residence_address}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_residence_address &&
                    Boolean(formik.errors.admin_residence_address)
                  }
                  helperText={
                    formik.touched.admin_residence_address &&
                    formik.errors.admin_residence_address
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Residence Pincode"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="number"
                  name="admin_residence_pincode"
                  value={formik.values.admin_residence_pincode}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_residence_pincode &&
                    Boolean(formik.errors.admin_residence_pincode)
                  }
                  helperText={
                    formik.touched.admin_residence_pincode &&
                    formik.errors.admin_residence_pincode
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="PAN"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="admin_pan"
                  value={formik.values.admin_pan}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_pan && Boolean(formik.errors.admin_pan)
                  }
                  helperText={
                    formik.touched.admin_pan && formik.errors.admin_pan
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Adhaar"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="admin_aadhaar_number"
                  value={formik.values.admin_aadhaar_number}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_aadhaar_number &&
                    Boolean(formik.errors.admin_aadhaar_number)
                  }
                  helperText={
                    formik.touched.admin_aadhaar_number &&
                    formik.errors.admin_aadhaar_number
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="UAN"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="admin_uan"
                  value={formik.values.admin_uan}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_uan && Boolean(formik.errors.admin_uan)
                  }
                  helperText={
                    formik.touched.admin_uan && formik.errors.admin_uan
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  select
                  label="Marital Status"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={formik.values.admin_marital_status}
                  onChange={(event) => {
                    const selectedValue = event.target.value; // Get the selected value from the event
                    formik.setFieldValue("admin_marital_status", selectedValue); // Set the value in Formik
                    console.log(selectedValue); // Log the selected value
                  }}
                  onBlur={formik.handleBlur}
                >
                  {maritalStatus.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item size={6}>
                <Autocomplete
                  options={qualifications || []} // Ensure data is not null or undefined
                  getOptionLabel={(option) => option.display_name}
                  onChange={(event, value) => {
                    formik.setFieldValue(
                      "admin_qualification",
                      value ? value.id : ""
                    ); // Set the selected country code
                  }}
                  onBlur={formik.handleBlur}
                  name="admin_qualification"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Qualification"
                      variant="outlined"
                      margin="normal"
                      error={
                        formik.touched.admin_qualification &&
                        Boolean(formik.errors.admin_qualification)
                      }
                      helperText={
                        formik.touched.admin_qualification &&
                        formik.errors.admin_qualification
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Designation"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  name="admin_designation"
                  value={formik.values.admin_designation}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_designation &&
                    Boolean(formik.errors.admin_designation)
                  }
                  helperText={
                    formik.touched.admin_designation &&
                    formik.errors.admin_designation
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Department"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  name="admin_department"
                  value={formik.values.admin_department}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_department &&
                    Boolean(formik.errors.admin_department)
                  }
                  helperText={
                    formik.touched.admin_department &&
                    formik.errors.admin_department
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Employee Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  name="admin_employee_number"
                  value={formik.values.admin_employee_number}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_employee_number &&
                    Boolean(formik.errors.admin_employee_number)
                  }
                  helperText={
                    formik.touched.admin_employee_number &&
                    formik.errors.admin_employee_number
                  }
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Working Location Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="admin_working_location"
                  value={formik.values.admin_working_location}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_working_location &&
                    Boolean(formik.errors.admin_working_location)
                  }
                  helperText={
                    formik.touched.admin_working_location &&
                    formik.errors.admin_working_location
                  }
                />
              </Grid>
              <Grid item size={6}>
                <DatePicker
                  name="admin_date_of_joining"
                  label="Date of Joining"
                  value={formik.values.admin_date_of_joining || null} // Ensure a valid date or null
                  onChange={(date) =>
                    formik.setFieldValue("admin_date_of_joining", date)
                  } // Use setFieldValue to update Formik value
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        formik.touched.admin_date_of_joining &&
                        Boolean(formik.errors.admin_date_of_joining)
                      }
                      helperText={
                        formik.touched.admin_date_of_joining &&
                        formik.errors.admin_date_of_joining
                      }
                    />
                  )}
                  onBlur={formik.handleBlur} // Use Formik's built-in handleBlur
                />
              </Grid>
              <Grid item size={6}>
                <TextField
                  label="Klockmate Referred By"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="admin_klockmate_referred_by"
                  value={formik.values.admin_klockmate_referred_by}
                  onChange={formik.handleChange}
                  onBlur={handleBlur} // Use custom handleBlur
                  error={
                    formik.touched.admin_klockmate_referred_by &&
                    Boolean(formik.errors.admin_klockmate_referred_by)
                  }
                  helperText={
                    formik.touched.admin_klockmate_referred_by &&
                    formik.errors.admin_klockmate_referred_by
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </LocalizationProvider>
    </>
  );
};

export default StepThree;
