import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Box, Button } from "@mui/material";
// import Grid from "@mui/material/Grid2";
// import { ExportCsv } from "@mui/x-data-grid-export"; // For CSV export

const DataTable = ({ data, columns, renderActions, pageSize }) => {
  //   const [pageSize, setPageSize] = useState(5); // Pagination size state
  const [exportData, setExportData] = useState([]); // For export

  // Function to export data to CSV
  //   const handleExport = () => {
  //     const csvData = data.map((row) => {
  //       const rowData = {};
  //       columns.forEach((col) => {
  //         rowData[col.field] = row[col.field];
  //       });
  //       return rowData;
  //     });
  //     setExportData(csvData); // Set the data to export
  //     // Trigger export
  //     ExportCsv({ rows: csvData, columns });
  //   };

  // Define action column
  const actionColumn = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    sortable: false, // No sorting for action column
    renderCell: (params) => {
      return renderActions(params); // Render custom actions
    },
  };

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {/* <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginBottom: 2 }}
        >
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleExport}>
              Export to CSV
            </Button>
          </Grid>
        </Grid> */}

      <DataGrid
        rows={data}
        columns={[...columns, actionColumn]}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        disableSelectionOnClick
        pagination
        components={{
          NoRowsOverlay: () => (
            <div style={{ textAlign: "center", padding: "10px" }}>
              No data available
            </div>
          ),
        }}
        componentsProps={{
          columnMenu: {
            sx: {
              backgroundColor: "#f5f5f5",
              borderRadius: "4px",
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]} // Pagination options
        stickyHeader // Sticky header
        disableColumnMenu // Disable column menu
      />
    </Box>
  );
};

export default DataTable;
