import React, { useState, useEffect } from "react";
import roleRightsData from "./roleRightsData.json";
import { MdAdd, MdClose, MdModeEdit, MdCheckCircle } from "react-icons/md";
import MyCard from "../../components/common/MyCard";
import { Button } from "@mui/material";

const RoleRight = ({ formData, onStepDataChange }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRights, setSelectedRights] = useState({});
  const [roles, setRoles] = useState(formData?.roles || []);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (formData?.roles) {
      setRoles(formData.roles);
    }
  }, [formData]);

  const handleOpenPopup = (role = null, index = null) => {
    if (role) {
      // Pre-populate selected rights when editing
      setRoleName(role.name);
      setSelectedRights(
        role.rights.reduce((acc, right) => ({ ...acc, [right]: true }), {})
      );
      setIsEditing(true);
      setEditingIndex(index);
    } else {
      setRoleName("");
      setSelectedRights({});
      setIsEditing(false);
      setEditingIndex(null);
    }
    setErrorMessage("");
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleRightChange = (right) => {
    setSelectedRights((prev) => ({
      ...prev,
      [right]: !prev[right], // Toggle the selected state of the right
    }));
  };

  const handleConfirm = () => {
    if (!roleName.trim()) {
      setErrorMessage("Role name is required.");
      return;
    }

    const selectedRightsList = Object.entries(selectedRights)
      .filter(([_, isSelected]) => isSelected)
      .map(([right]) => right);

    if (selectedRightsList.length === 0) {
      setErrorMessage("Please select at least one right.");
      return;
    }

    const roleExists = roles.some(
      (role, index) =>
        role.name.toLowerCase() === roleName.toLowerCase() &&
        index !== editingIndex
    );
    if (roleExists) {
      alert("Role name already exists. Please choose a different name.");
      return;
    }

    const updatedRoles = isEditing
      ? roles.map((role, idx) =>
          idx === editingIndex
            ? { ...role, name: roleName, rights: selectedRightsList }
            : role
        )
      : [...roles, { name: roleName, rights: selectedRightsList }];

    setRoles(updatedRoles);
    onStepDataChange({ roles: updatedRoles });
    handleClosePopup();
  };

  return (
    <div className="space-y-6 ">
      {/* Add Role Button */}
      <div className="flex justify-end px-7">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenPopup()}
        >
          <MdAdd className="mr-2" />
          Add Role
        </Button>
      </div>
      {/* Roles Card View using MyCard */}
      <div className="space-y-4 flex flex-col p-2 ">
        {roles.map((role, index) => (
          <MyCard
            key={index}
            margin="mb-10"
            title={
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-bold">{role.name}</h3>
                <MdModeEdit
                  className="text-xl cursor-pointer"
                  onClick={() => handleOpenPopup(role, index)}
                />
              </div>
            }
            width="full"
          >
            {/* Display only categories with selected rights */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-2">
              {Object.entries(roleRightsData).map(([category, rights]) => {
                // Filter only selected rights for each category
                const selectedRights = rights.filter(({ rights: right }) =>
                  role.rights.includes(right)
                );
                return selectedRights.length > 0 ? ( // Only render if there are selected rights
                  <div key={category} className="mb-4">
                    <h4 className="font-semibold mb-2">{category}</h4>
                    <ul>
                      {selectedRights.map(({ rights: right }) => (
                        <li
                          key={right}
                          className="flex items-center gap-2 text-green-500"
                        >
                          <MdCheckCircle />
                          <span>{right}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null;
              })}
            </div>
          </MyCard>
        ))}
      </div>

      {/* Role Rights Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 p-4 ">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl w-full max-h-[80vh]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">
                {isEditing ? "Edit Role" : "Add New Role"}
              </h2>
              <button onClick={handleClosePopup}>
                <MdClose className="text-xl" />
              </button>
            </div>

            {/* Role Name Input */}
            <input
              type="text"
              value={roleName}
              onChange={handleRoleNameChange}
              placeholder="Enter role name"
              className="w-full p-2 border rounded mb-4"
            />
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}

            {/* Rights Selection - Column Layout */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {Object.entries(roleRightsData).map(([category, rights]) => (
                <div key={category} className="mb-4">
                  <h3 className="font-semibold mb-2">{category}</h3>
                  {rights.map(({ rights: right }) => (
                    <label key={right} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedRights[right] || false} // Manage rights per role
                        onChange={() => handleRightChange(right)}
                      />
                      <span>{right}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>

            {/* Confirm Button */}
            <div className="flex justify-end mt-6">
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleRight;
